// ./components/designers/nav/DesignerHome.jsx

// Chakra
import { Box, Heading, VStack } from '@chakra-ui/react';

// MdM Icons
import { ClientsIcon, DesignSourcingIcon } from '../../../common/icons';

// MdM
import NavGrid from '../../common/pages/NavGrid';

const DesignerHome = () => {
  // ------------------------------ Data ------------------------------ //

  const options = [
    {
      title: 'Project Management',
      description: 'Update details about client projects and track transactions.',
      path: '/pm',
      buttonText: 'Go to Project Management',
      disabled: true,
      icon: <ClientsIcon size={24} />,
    },
    {
      title: 'Design Sourcing',
      description: 'Manage your vendors and products.',
      path: '/sourcing',
      buttonText: 'Go to Design Sourcing',
      icon: <DesignSourcingIcon size={24} />,
    },
  ];

  // ------------------------------ Render ------------------------------ //

  return (
    <VStack h="100%" w="100%" p={4}>
      <Box h="100%" w="90%">
        <Heading mb={6}>Designer Home</Heading>
        <NavGrid options={options} />
      </Box>
    </VStack>
  );
};

export default DesignerHome;
