// ./components/common/EditableListItem.jsx

// React
import React, { useCallback, useEffect, useRef, useState } from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Chakra
import {
  AlertDialog, AlertDialogOverlay, AlertDialogContent,
  AlertDialogHeader, AlertDialogBody, AlertDialogFooter,
  Box, Button, HStack, Input, IconButton, Select, Text, useDisclosure,
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, DeleteIcon, CloseIcon } from '@chakra-ui/icons';

// MdM
import DynamicSelect from '../fields/DynamicSelect';
import { updateCalculatedFields } from '../../../utils/calculations';

const EditableListItem = ({ fieldsConfig, fields, onSave, onDelete, detailsPath }) => {
  // ------------------------------ Hooks ------------------------------ //

  // Router
  const navigate = useNavigate();

  // State
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState({ ...fields });
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  // Refs
  const containerRef = useRef();
  const cancelRef = useRef();

  // ------------------------------ Callbacks ------------------------------ //

  const handleEditableFieldChange = useCallback((updates) => {
    setEditedFields((prevFields) => updateCalculatedFields({
      ...prevFields,
      ...updates,
    }, fieldsConfig));
  }, [fieldsConfig]);

  const handleEdit = useCallback((e) => {
    setIsEditing(true);
    e.stopPropagation();
  }, []);

  const handleSave = useCallback((e) => {
    setIsEditing(false);

    const newFields = fieldsConfig.flat().reduce((acc, { fieldName, fieldType, args }) => {
      if (fieldType === 'money') {
        const parsedValue = parseFloat(editedFields[fieldName]);
        acc[fieldName] = isNaN(parsedValue) ? '' : parsedValue;
      } else if (fieldType === 'choice') {
        Object.keys(args.fieldsMapping).forEach((key) => {
          acc[key] = editedFields[key];
        });
      } else {
        acc[fieldName] = editedFields[fieldName];
      }
      return acc;
    }, {});

    const updatedFields = updateCalculatedFields({
      ...editedFields,
      ...newFields,
    }, fieldsConfig);

    onSave(updatedFields);
    e.stopPropagation();
  }, [onSave, fieldsConfig, editedFields]);

  const handleCancel = useCallback((e) => {
    handleEditableFieldChange({ ...fields });
    setIsEditing(false);
    e.stopPropagation();
  }, [handleEditableFieldChange, fields]);

  const handleDelete = useCallback((e) => {
    onDeleteAlertOpen();
    e.stopPropagation();
  }, [onDeleteAlertOpen]);

  const handleNavigateToDetails = useCallback(() => {
    if (!isEditing) navigate(`/${detailsPath}/${fields.id}`);
  }, [isEditing, navigate, detailsPath, fields]);

  // ------------------------------ Effects ------------------------------ //

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isEditing && containerRef.current && !containerRef.current.contains(event.target)) {
        handleCancel(event); // Cancel editing when clicking outside
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, handleCancel]);

  // ------------------------------ Render ------------------------------ //

  const renderChoice = (fieldName, fieldHeading, value, filterKey, flex, args) => (
    <DynamicSelect
      key={fieldName}
      flex={flex}
      fieldHeading={fieldHeading}
      value={value || ""}
      filterValue={filterKey}
      args={args}
      onChange={(selectedValues) => handleEditableFieldChange(selectedValues)}
    />
  );

  const renderYesNo = (fieldName, value, flex) => (
    <Box flex={flex}>
      <Select
        placeholder="Select"
        value={value}
        onChange={(e) => handleEditableFieldChange({
          [fieldName]: e.target.value === 'true'
        })}
      >
        <option key="yes" value="true">Yes</option>
        <option key="no" value="false">No</option>
      </Select>
    </Box>
  );

  const renderInput = (fieldName, fieldType, value, flex) => (
    <Input
      key={fieldName}
      id={`editable-${fieldName}`}
      value={value}
      onChange={(e) => handleEditableFieldChange({
        [fieldName]: e.target.value
      })}
      onKeyDown={(e) => e.key === 'Enter' && handleSave(e)}
      fontSize="md"
      flex={flex}
      isDisabled={fieldType === 'fixed'}
    />
  );

  const renderText = (fieldName, fieldType, value, flex) => {
    const isMoney = fieldType === 'money' || fieldType === 'calculated';
    const isBool = fieldType === 'flag';
    const invalidValue = (
      (isMoney && typeof value !== 'number')
      || (isBool && typeof value !== 'boolean')
      || (!isMoney && !isBool && (value === null || value === undefined))
    );
    let displayValue = null;
    if (!invalidValue) {
      displayValue = (
        isMoney ? `$${value.toFixed(2)}` :
        isBool ? (value ? 'yes' : 'no') :
        value
      );
    }
    
    return (
      <Text
        key={fieldName}
        fontSize="md"
        noOfLines={1}
        cursor="pointer"
        flex={flex}
        color={invalidValue ? 'mdm.medium' : 'white'}
      >
        {invalidValue ? `[Enter value]`: displayValue}
      </Text>
    )
  };

  const renderField = (fieldConfig, currentfields, value) => {
    const { fieldName, fieldHeading, fieldType, flex, hidden, alwaysHidden, args } = fieldConfig;
    const filterKey = args?.filterKey ? currentfields[args.filterKey] : undefined;
    
    if (hidden || alwaysHidden) return null;
    
    if (!isEditing || fieldType === 'calculated') {
      return renderText(fieldName, fieldType, value, flex);
    } else if (fieldType === 'choice') {
      return renderChoice(fieldName, fieldHeading, value, filterKey, flex, args);
    } else if (fieldType === 'flag') {
      return renderYesNo(fieldName, value, flex);
    }
    
    return renderInput(fieldName, fieldType, value, flex);
  };

  return (
    <>
      <HStack
        ref={containerRef}
        w="100%"
        p={2}
        borderWidth="1px"
        borderRadius="md"
        alignItems="center"
        spacing={4}
        onClick={handleNavigateToDetails}
        cursor="pointer"
        _hover={{ background: 'mdm.dark_medium' }}
      >
        {/* Render fields dynamically */}
        {fieldsConfig.flat().map((fieldConfig) => renderField(
          fieldConfig,
          fields,
          isEditing ? editedFields[fieldConfig.fieldName] : fields[fieldConfig.fieldName],
        ))}

        {/* Action Buttons */}
        {isEditing ? (
          <HStack h="100%">
            <IconButton
              aria-label="Save"
              icon={<CheckIcon />}
              colorScheme="green"
              onClick={handleSave}
            />
            <IconButton
              aria-label="Cancel"
              icon={<CloseIcon />}
              colorScheme="gray"
              onClick={handleCancel}
            />
          </HStack>
        ) : (
          <HStack h="100%">
            <IconButton
              aria-label="Edit"
              icon={<EditIcon />}
              colorScheme="blue"
              onClick={handleEdit}
            />
            <IconButton
              aria-label="Delete"
              icon={<DeleteIcon />}
              colorScheme="red"
              onClick={handleDelete}
            />
          </HStack>
        )}
      </HStack>
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="mdm.dark">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Item
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this item?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => {
                onDelete();
                onDeleteAlertClose();
              }} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EditableListItem;