// ./components/designers/vendors/VendorDetailsPage.jsx

// React
import React, { useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Firestore
import { where } from 'firebase/firestore';

// MdM
import DetailsPage from '../../common/pages/DetailsPage';
import EditableDbList from '../../common/lists/EditableDbList';
import useDocDetailsData from '../../common/hooks/useDocDetailsData';
import { productFields } from '../products/productFields';
import { vendorFields } from './vendorFields';

const VendorDetailsPage = () => {
  // ------------------------------ Hooks ------------------------------ //

  const { vendorId } = useParams();
  const {
    data: vendorData, loading, onSave,
  } = useDocDetailsData('vendors', vendorId, vendorFields);

  // ------------------------------ Memos ------------------------------ //

  // Modify project fields config to override client fields
  const modifiedProductFields = useMemo(() => {
    if (!vendorData) return null;
    return productFields.map(columnFields => {
      // 1. We're mapping over productFields, which is an array of arrays
      // Each columnFields is one of those inner arrays

      // 2. We only modify the array that contains supplier
      if (columnFields.some(field => field.fieldName === 'supplier')) {
        return [
          // 3. This spread operator maps all fields in the original array
          ...columnFields.map(fieldConfig => {
            // 3a. If it's supplier, modify it
            if (fieldConfig.fieldName === 'supplier') {
              return {
                ...fieldConfig,
                fieldType: 'fixed',
                args: undefined,
                fixedValue: vendorData.name,
              };
            }
            // 3b. If it's not supplier, return unchanged
            return fieldConfig;
          }),
          // 4. Add the new vendorId field to this same array
          {
            fieldName: 'supplierVendorId',
            fieldHeading: 'Supplier Vendor ID',
            fieldType: 'fixed',
            fixedValue: vendorId,
            alwaysHidden: true,
          }
        ];
      }
      // 5. For arrays that don't contain vendorName, return them unchanged
      return columnFields;
    });
  }, [vendorData, vendorId]);

  // ------------------------------ Render ------------------------------ //

  return (
    <DetailsPage
      docData={vendorData}
      loading={loading}
      heading={`Vendor: ${vendorData?.name}`}
      parentTitle={"Vendors"}
      parentPath={"/sourcing/vendors"}
      fieldsConfig={vendorFields}
      onSave={onSave}
    >
      <EditableDbList
        title="Products"
        parentPath={`sourcing/vendors/${vendorId}`}
        listId='vendor-products-list'
        collectionName="products"
        fieldsConfig={modifiedProductFields}
        sortColumns={["supplier", "description"]}
        dbQuery={[where('supplierVendorId', '==', vendorId)]}
      />
    </DetailsPage>
  );
}

export default VendorDetailsPage;
