// ./components/designers/product/ProductsListPage.jsx

// React
import React from 'react';

// MdM
import EditableDbList from '../../common/lists/EditableDbList';
import Page from '../../common/pages/Page';
import { productFields } from './productFields';

const ProductsListPage = () => (
  <Page
    parentTitle="Design Sourcing"
    parentPath="/sourcing"
  >
    <EditableDbList
      title='Products'
      parentPath="sourcing"
      listId='products-list'
      collectionName="products"
      fieldsConfig={productFields}
      sortColumns={["supplier", "description"]}
    />
  </Page>
);

export default ProductsListPage;