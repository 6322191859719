// ./components/common/ReadOnlyField.jsx

// Chakra
import { HStack, Text } from '@chakra-ui/react';

const ReadOnlyField = ({ label, value, isMoney=false }) => {
  const invalidValue = (
    (isMoney && typeof value !== 'number')
    || (!isMoney && (value === null || value === undefined))
  );
  let displayValue = invalidValue ? `[Enter Value]` : (
    isMoney ? `$${value.toFixed(2)}` : value
  );

  return (
    <HStack w="100%" p={2} pl={4} alignItems="center" spacing={4}>
      <Text w={200} fontSize="lg" fontWeight="bold" noOfLines={1}>{label}:</Text>
      <Text fontSize="lg" noOfLines={1}>{displayValue}</Text>
    </HStack>
  );
};

export default ReadOnlyField;