export const calculateValue = (operation, values) => {
  // Filter out undefined/null, but keep 0
  const validValues = values.filter(v => v !== undefined && v !== null)
    .map(v => typeof v === 'string' ? parseFloat(v) : v);

  // Return null if we don't have all required values
  if (validValues.length !== values.length) return null;

  switch (operation) {
    case 'sum':
      return validValues.reduce((acc, val) => acc + val, 0);
    case 'product':
      return validValues.reduce((acc, val) => acc * val, 1);
    case 'difference':
      // For difference, we need exactly 2 values
      return validValues.length === 2 ? validValues[0] - validValues[1] : null;
    default:
      return null;
  }
};

export const updateCalculatedFields = (fields, fieldsConfig) => {
  const updatedFields = { ...fields };

  // Get all calculated fields
  // Flatten the list of lists into a single list of configs
  const calculatedFields = fieldsConfig.flat().filter(field => field.fieldType === 'calculated');
  
  // Update each calculated field
  calculatedFields.forEach(({ fieldName, args }) => {
    const { operation, fields: fieldNames } = args;
    const values = fieldNames.map(name => updatedFields[name]);
    updatedFields[fieldName] = calculateValue(operation, values);
  });
  
  return updatedFields;
};