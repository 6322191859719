// ./components/common/DynamicSelect.jsx

// React
import React, { useEffect, useState, useCallback } from 'react';

// Firestore
import { where } from 'firebase/firestore';

// Chakra
import { Box, Select } from '@chakra-ui/react';

// MdM
import { getDocuments } from '../../../data/documents';

const DynamicSelect = ({ fieldHeading, value, onChange, args, flex, filterValue }) => {
  // ------------------------------ Hooks ------------------------------ //

  // State
  const [options, setOptions] = useState([]);

  // ------------------------------ Callbacks ------------------------------ //

  const fetchOptions = useCallback(async () => {
    if (!args?.collection) return;
    const documents = await getDocuments(
      args.collection,
      filterValue ? [where(args.filterKey, '==', filterValue)] : undefined,
    );

    // Sort the documents alphabetically by the lookupKey
    const sortedDocuments = documents.sort((a, b) => {
      const textA = a[args.lookupKey]?.toString().toLowerCase();
      const textB = b[args.lookupKey]?.toString().toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setOptions(sortedDocuments);
  }, [args, filterValue]);

  // ------------------------------ Effects ------------------------------ //

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  // ------------------------------ Rendering ------------------------------ //

  return (
    <Box flex={flex}>
      <Select
        placeholder={`Select ${fieldHeading}`}
        value={value || ""}
        onChange={(e) => {
          const selectedOption = options.find(
            (option) => option[args.lookupKey] === e.target.value
          );
          onChange(Object.entries(args.fieldsMapping).reduce(
            (acc, [key, mapping]) => ({
              ...acc,
              [key]: mapping.key ? selectedOption[mapping.key] : mapping.constant,
            }),
            {}
          ));
        }}
      >
        {options.map((option) => (
          <option key={option[args.lookupKey]} value={option[args.lookupKey]}>
            {option[args.lookupKey]}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default DynamicSelect;