// ./components/designers/projects/projectFields.js

export const projectFields = [
  [
    {
      fieldName: 'clientName',
      fieldHeading: 'Client',
      fieldType: 'choice',
      flex: 1,
      args: {
        collection: 'clients',
        lookupKey: 'displayName',
        fieldsMapping: {
          clientName: { key: 'displayName' },
          clientId: { key: 'id' },
        },
      },
    },
    { fieldName: 'name', fieldHeading: 'Project', fieldType: 'string', flex: 1 },
    { fieldName: 'description', fieldHeading: 'Description', fieldType: 'string', flex: 2 },
  ]
];
