// ./components/designers/clients/clientFields.js

export const clientFields = [
  [
    { fieldName: 'displayName', fieldHeading: 'Name', fieldType: 'string', flex: 1 },
    { fieldName: 'companyName', fieldHeading: 'Company', fieldType: 'string', flex: 1 },
    { fieldName: 'email', fieldHeading: 'Email', fieldType: 'string', flex: 1 },
    { fieldName: 'phone', fieldHeading: 'Phone', fieldType: 'string', flex: 1 },
  ], [
    { fieldName: 'streetAddress', fieldHeading: 'Street', fieldType: 'string', flex: 1 },
    { fieldName: 'city', fieldHeading: 'City', fieldType: 'string', flex: 1 },
    { fieldName: 'state', fieldHeading: 'State', fieldType: 'string', flex: 1 },
    { fieldName: 'zipCode', fieldHeading: 'Zip Code', fieldType: 'string', flex: 1 },
  ],
];
