// ./components/designers/products/ProductDetailsPage.jsx

// React
import React from 'react';

// Router
import { useParams } from 'react-router-dom';

// MdM
import DetailsPage from '../../common/pages/DetailsPage';
import useDocDetailsData from '../../common/hooks/useDocDetailsData';
import { productFields } from './productFields';

const ProductDetailsPage = () => {
  // ------------------------------ Hooks ------------------------------ //

  const { productId, vendorId } = useParams();
  const {
    data: productData, loading, onSave,
  } = useDocDetailsData('products', productId, productFields);

  const parentTitle = vendorId ? "Vendor Details" : "Products";
  const parentPath = vendorId ? `/sourcing/vendors/${vendorId}` : "/sourcing/products";

  // ------------------------------ Render ------------------------------ //

  return (
    <DetailsPage
      docData={productData}
      loading={loading}
      heading={`Product: ${productData?.description}`}
      parentTitle={parentTitle}
      parentPath={parentPath}
      fieldsConfig={productFields}
      onSave={onSave}
    />
  );
};

export default ProductDetailsPage;