// ./components/designers/projects/ProjectDetailsPage.jsx

// React
import React, { useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Firestore
import { where } from 'firebase/firestore';

// MdM
import DetailsPage from '../../common/pages/DetailsPage';
import EditableDbList from '../../common/lists/EditableDbList';
import useDocDetailsData from '../../common/hooks/useDocDetailsData';
import { selectionFields } from '../selections/selectionFields';
import { projectFields } from './projectFields';

const ProjectDetailsPage = () => {
  // ------------------------------ Hooks ------------------------------ //

  const { projectId, clientId } = useParams();
  const {
    data: projectData, loading, onSave,
  } = useDocDetailsData('projects', projectId, projectFields);

  const parentTitle = clientId ? "Client Details" : "Projects";
  const parentPath = clientId ? `/pm/clients/${clientId}` : "/pm/projects";

  // ------------------------------ Memos ------------------------------ //

  const modifiedSelectionFields = useMemo(() => {
    // Set all the fixed fields to the correct values
    if (!projectData) return null;
    return selectionFields.map((column) => {
      if (column.fieldName === 'projectName') {
        return { ...column, fixedValue: projectData.name };
      } else if (column.fieldName === 'projectId') {
        return { ...column, fixedValue: projectId };
      } else if (column.fieldName === 'clientName') {
        return { ...column, fixedValue: projectData.clientName };
      } else if (column.fieldName === 'clientId') {
        return { ...column, fixedValue: projectData.clientId };
      } else if (column.fieldName === 'fixed') {
        console.error('Unexpected fixed-value column found in selectionFields:', column);
      }
      return column;
    });
  }, [projectData, projectId]);

  // ------------------------------ Render ------------------------------ //

  return (
    <DetailsPage
      docData={projectData}
      loading={loading}
      heading={`Project: ${projectData?.name}`}
      parentTitle={parentTitle}
      parentPath={parentPath}
      fieldsConfig={projectFields}
      onSave={onSave}
    >
      <EditableDbList
        title="Selections"
        parentPath={`pm/projects/${projectId}`}
        listId='project-selections-list'
        collectionName="selections"
        fieldsConfig={modifiedSelectionFields}
        sortColumns={["clientRoom", "mdmItemNumber"]}
        dbQuery={[where('projectId', '==', projectId)]}
      />
    </DetailsPage>
  );
}

export default ProjectDetailsPage;
