// ./components/common/renderDetailsField.jsx

// React
import React from 'react';

// Chakra
import { Box, HStack, Text, Select } from '@chakra-ui/react';

// MdM
import DynamicSelect from './DynamicSelect';
import EditableField from './EditableField';
import ReadOnlyField from './ReadOnlyField';

/**
 * Renders a field based on its configuration and type
 * @param {Object} fieldConfig - Configuration for the field
 * @param {Object} data - The data object containing the field value
 * @param {Object} onSave - Object containing save callbacks
 */
export const renderDetailsField = (fieldConfig, data, onSave) => {
  const { fieldName, fieldHeading, fieldType, args } = fieldConfig;
  const value = data?.[fieldName];

  switch (fieldType) {
    case 'choice':
      return (
        <HStack key={fieldName} w="100%" pl={4} alignItems="center" spacing={4}>
          <Text w={200} fontSize="lg" fontWeight="bold">
            {fieldHeading}:
          </Text>
          <Box>
            <DynamicSelect
              fieldHeading={fieldHeading}
              key={fieldName}
              value={value}
              filterValue={args?.filterKey ? data?.[args.filterKey] : undefined}
              args={args}
              onChange={onSave}
              flex={1}
            />
          </Box>
        </HStack>
      );

    case 'string':
    case 'number':
    case 'money':
      return (
        <EditableField
          key={fieldName}
          displayName={fieldHeading}
          fieldName={fieldName}
          value={value}
          onSave={onSave}
          fontSize="lg"
          format={fieldType}
        />
      );

    case 'calculated':
      return (
        <ReadOnlyField
          key={fieldName}
          label={fieldHeading}
          value={value}
          isMoney={true}
        />
      );

    case 'flag':
      return (
        <HStack key={fieldName} w="100%" pl={4} alignItems="center" spacing={4}>
          <Text w={200} fontSize="lg" fontWeight="bold">
            {fieldHeading}:
          </Text>
          <Box>
            <Select
              placeholder="Select"
              value={value ? 'true' : 'false'}
              onChange={(e) => onSave({ [fieldName]: e.target.value === 'true' })}
            >
              <option key="yes" value="true">Yes</option>
              <option key="no" value="false">No</option>
            </Select>
          </Box>
        </HStack>
      );

    default:
      return (
        <ReadOnlyField
          key={fieldName}
          label={fieldHeading}
          value={value}
        />
      );
  }
};