// ./components/common/EditableField.jsx

// React
import React, { useCallback, useEffect, useRef, useState } from 'react';

// Chakra
import {
  HStack, Input, IconButton, Text,
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';

const EditableField = ({
  displayName, fieldName, value, onSave, fontSize, format,
}) => {
  // ------------------------------ Hooks ------------------------------ //

  // State
  const [isEditing, setIsEditing] = useState(false);
  const [editedField, setEditedField] = useState(value);

  // Refs
  const containerRef = useRef();
  const inputRef = useRef();

  // Derived
  const isMoney = format === 'money';
  const isNumber = format === 'money' || format === 'number';
  const invalidValue = (
    (isMoney && typeof value !== 'number')
    || (!isMoney && (value === null || value === undefined))
  );
  const displayValue = invalidValue ? `[Enter Value]` : (
    isMoney ? `$${value.toFixed(2)}` : value
  );

  // ------------------------------ Callbacks ------------------------------ //

  const handleEdit = useCallback((e) => {
    setIsEditing(true);
    e.stopPropagation();
  }, []);

  const handleSave = useCallback((e) => {
    setIsEditing(false);
    
    if (isNumber) {
      const parsedValue = parseFloat(editedField);
      if (!isNaN(parsedValue)) onSave({ [fieldName]: parsedValue });
    } else {
      onSave({ [fieldName]: editedField });
    }

    e.stopPropagation();
  }, [isNumber, onSave, fieldName, editedField]);

  const handleCancel = useCallback((e) => {
    setEditedField(value);
    setIsEditing(false);
    e.stopPropagation();
  }, [value]);

  // ------------------------------ Effects ------------------------------ //

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isEditing && containerRef.current && !containerRef.current.contains(event.target)) {
        handleCancel(event); // Cancel editing when clicking outside
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, handleCancel]);

  // ------------------------------ Render ------------------------------ //

  return (
    <HStack
      w="100%"
      ref={containerRef}
      pl={4}
      alignItems="center"
      spacing={4}
    >
      <Text w={200} fontSize={fontSize} fontWeight="bold" noOfLines={1}>
        {`${displayName}:`}
      </Text>

      {isEditing ? (
        <Input
          ref={inputRef}
          value={editedField}
          onChange={(e) => setEditedField(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSave(e)}
          fontSize={fontSize}
        />
      ) : (
        <Text
          fontSize={fontSize}
          noOfLines={1}
          color={invalidValue ? 'mdm.medium' : 'white'}
        >
          {displayValue}
        </Text>
      )}

      {/* Action Buttons */}
      {isEditing ? (
        <HStack h="100%">
          <IconButton
            aria-label="Save"
            icon={<CheckIcon />}
            colorScheme="green"
            onClick={handleSave}
          />
          <IconButton
            aria-label="Cancel"
            icon={<CloseIcon />}
            colorScheme="gray"
            onClick={handleCancel}
          />
        </HStack>
      ) : (
        <IconButton
          aria-label="Edit"
          icon={<EditIcon />}
          colorScheme="blue"
          variant="ghost"
          onClick={handleEdit}
        />
      )}
    </HStack>
  );
};

export default EditableField;