// ./components/common/DetailsPage.jsx

// React
import React from 'react';

// Chakra
import { Heading, HStack, VStack } from '@chakra-ui/react';

// MdM
import Page from './Page';
import { CenterBox, OverlaySpinner } from './DataLoading';
import { renderDetailsField } from '../fields/renderDetailsField';

/**
 * A reusable page layout for displaying doc details.
 */
const DetailsPage = ({
  docData, loading, heading, parentTitle, parentPath, fieldsConfig, onSave, children,
}) => (
  <Page parentTitle={parentTitle} parentPath={parentPath}>
    {/* Spinner for loading */}
    {loading && <OverlaySpinner />}

    {!docData ? (
      <CenterBox>
        {loading ? 'Loading data...' : 'No data found.'}
      </CenterBox>
    ) : (
      <VStack width="100%" height="100%" alignItems="start" spacing={6}>
        {/* Heading, e.g. "Project: My Project" */}
        <Heading>{heading}</Heading>

        {/* Render each group of fields */}
        <HStack className="details-fields" w="100%" alignItems="start">
          {fieldsConfig.map((fieldColConfig, idx) => (
            <VStack key={idx} w="80%" alignItems="start" spacing={4}>
              {fieldColConfig.map((fieldConfig) =>
                renderDetailsField(fieldConfig, docData, onSave)
              )}
            </VStack>
          ))}
        </HStack>

        {/* Anything else unique to the page (EditableDbList, etc.) */}
        {children}
      </VStack>
    )}
  </Page>
);

export default DetailsPage;