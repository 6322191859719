// ./data/documents.js

// Firestore
import {
  addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc,
} from 'firebase/firestore';

// MdM
import { db } from '../common/firebase';

export async function getDocuments(collectionName, queryConstraints = []) {
  try {
    const collectionRef = collection(db, collectionName);
    const q = queryConstraints.length > 0
      ? query(collectionRef, ...queryConstraints)
      : collectionRef;
    const snapshot = await getDocs(q);
    return snapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
  } catch (error) {
    console.error(`Error fetching documents from ${collectionName}:`, error);
    throw error;
  }
}

export async function getDocumentById(collectionName, documentId) {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return null;
    }

    return { id: docSnap.id, ...docSnap.data() };
  } catch (error) {
    console.error(`Error fetching document ${documentId} from ${collectionName}:`, error);
    throw error;
  }
}

export async function createDocument(collectionName, newDocument) {
  await addDoc(collection(db, collectionName), newDocument);
}

export async function saveDocument(collectionName, id, newDocument) {
  const { id: _, ...updatedDocument } = newDocument;
  const filteredDocument = Object.fromEntries(
    Object.entries(updatedDocument).filter(([_, value]) => value !== undefined)
  );
  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, filteredDocument);
}

export async function removeDocument(collectionName, id) {
  const docRef = doc(db, collectionName, id);
  await deleteDoc(docRef);
}