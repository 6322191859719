// ./components/common/EditableDbList.jsx

// React
import React, { useEffect, useState, useCallback } from 'react';

// Chakra
import { Box } from '@chakra-ui/react';

// MdM
import {
  getDocuments, createDocument, saveDocument, removeDocument,
} from '../../../data/documents';
import { OverlaySpinner } from '../pages/DataLoading';
import EditableList from './EditableList';

const EditableDbList = ({ 
  title, parentPath, listId, collectionName, fieldsConfig, sortColumns, dbQuery,
}) => {
  // ------------------------------ Hooks ------------------------------ //

  // State
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);

  // ------------------------------ Callbacks ------------------------------ //

  const handleAddItem = useCallback((newFields) => {
    setLoading(true);
    return createDocument(collectionName, newFields)
      .then(() => getDocuments(collectionName, dbQuery))
      .then(setDocs)
      .finally(() => setLoading(false));
  }, [collectionName, dbQuery]);

  const handleSaveItem = useCallback((idToUpdate, updatedData) => {
    setLoading(true);
    return saveDocument(collectionName, idToUpdate, updatedData)
      .then(() => getDocuments(collectionName, dbQuery))
      .then(setDocs)
      .finally(() => setLoading(false));
  }, [collectionName, dbQuery]);

  const handleDeleteItem = useCallback((idToDelete) => {
    setLoading(true);
    return removeDocument(collectionName, idToDelete)
      .then(() => getDocuments(collectionName, dbQuery))
      .then(setDocs)
      .finally(() => setLoading(false));
  }, [collectionName, dbQuery]);

  const fetchDocuments = useCallback(() => {
    setLoading(true);
    getDocuments(collectionName, dbQuery)
      .then(setDocs)
      .finally(() => setLoading(false));
  }, [collectionName, dbQuery]);

  // ------------------------------ Effects ------------------------------ //

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  // ------------------------------ Render ------------------------------ //

  return (
    <Box w="100%" flex="1" position="relative">
      {loading && <OverlaySpinner />}
      <EditableList
        title={title}
        listId={listId}
        fieldsConfig={fieldsConfig}
        sortColumns={sortColumns}
        items={docs}
        addItem={handleAddItem}
        saveItem={handleSaveItem}
        deleteItem={handleDeleteItem}
        detailsPath={`${parentPath}/${collectionName}`}
      />
    </Box>
  );
};

export default EditableDbList;
