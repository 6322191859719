// ./components/designers/DesignerView.jsx

// React
import { useState, useRef, useEffect } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';

// Chakra
import { Box, Button, Flex, IconButton, Tooltip, VStack, Collapse } from '@chakra-ui/react';

// MdM Icons
import {
  DesignerHomeIcon, DesignSourcingIcon, ClientsIcon, CollapseMenuIcon,
  ProductsIcon, ProjectsIcon, SidebarIcon, VendorsIcon,
} from '../../common/icons';

// MdM
import DesignerHome from './nav/DesignerHome';
import DesignSourcing from './nav/DesignSourcing';
import DesignClients from './nav/DesignClients';
import ClientsListPage from './clients/ClientsListPage';
import ClientDetailsPage from './clients/ClientDetailsPage';
import ProductsListPage from './products/ProductsListPage';
import ProductDetailsPage from './products/ProductDetailsPage';
import ProjectsListPage from './projects/ProjectsListPage';
import ProjectDetailsPage from './projects/ProjectDetailsPage';
import SelectionDetailsPage from './selections/SelectionDetailsPage';
import VendorsListPage from './vendors/VendorsListPage';
import VendorDetailsPage from './vendors/VendorDetailsPage';

// MdM
const SidebarButton = ({ icon, children, onClick, showText, label, isDisabled, isSubpage }) => (
  showText ? (
    <Button
      leftIcon={icon}
      variant="ghost"
      justifyContent="flex-start"
      onClick={onClick}
      isDisabled={isDisabled}
      pl={isSubpage ? 8 : 4} // Indent subpages
    >
      {children}
    </Button>
  ) : (
    <Tooltip label={label} placement="right">
      <IconButton
        icon={icon}
        variant="ghost"
        onClick={onClick}
        aria-label={label}
        isDisabled={isDisabled}
      />
    </Tooltip>
  )
);

const DesignerView = () => {
  // ------------------------------ State ------------------------------ //

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const sidebarRef = useRef();

  // ------------------------------ Callback Definitions ------------------------------ //
  
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleNavigation = (path) => {
    navigate(path);
    setIsSidebarOpen(false);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // ------------------------------ Render ------------------------------ //

  const sidebarItems = [
    { icon: <DesignerHomeIcon size={24} />, label: 'Designer Home', path: '/' },
    {
      icon: <ClientsIcon size={24} />,
      label: 'Project Management',
      path: '/pm',
      subpages: [
        { icon: <ClientsIcon size={20} />, label: 'Clients', path: '/pm/clients' },
        { icon: <ProjectsIcon size={20} />, label: 'Projects', path: '/pm/projects' }
      ],
    },
    {
      icon: <DesignSourcingIcon size={24} />,
      label: 'Design Sourcing',
      path: '/sourcing',
      subpages: [
        { icon: <VendorsIcon size={20} />, label: 'Vendors', path: '/sourcing/vendors' },
        { icon: <ProductsIcon size={20} />, label: 'Products', path: '/sourcing/products' }
      ],
    },
  ];

  return (
    <Flex h="100%" position="relative">
      {/* Sidebar */}
      <Box
        ref={sidebarRef}
        position="fixed"
        left="0"
        bg="rgba(0, 0, 0, 0.9)"
        color="white"
        w={isSidebarOpen ? "250px" : "75px"}
        h="100%"
        transition="width 0.2s"
        zIndex="1"
      >
        <VStack align="stretch" p={4}>
          <IconButton
            icon={isSidebarOpen ? <CollapseMenuIcon size={24} /> : <SidebarIcon size={24} />}
            onClick={toggleSidebar}
            aria-label="Toggle Sidebar"
            variant="ghost"
            alignSelf="flex-end"
          />
          {sidebarItems.map((item, index) => (
            <div key={index}>
              <SidebarButton
                icon={item.icon}
                onClick={() => handleNavigation(item.path)}
                showText={isSidebarOpen}
                label={item.label}
                isDisabled={item.isDisabled}
              >
                {item.label}
              </SidebarButton>
              {item.subpages && (
                <Collapse in={isSidebarOpen}>
                  {item.subpages.map((subpage, subIndex) => (
                    <SidebarButton
                      key={subIndex}
                      icon={subpage.icon}
                      onClick={() => handleNavigation(subpage.path)}
                      showText={isSidebarOpen}
                      label={subpage.label}
                      isSubpage
                    >
                      {subpage.label}
                    </SidebarButton>
                  ))}
                </Collapse>
              )}
            </div>
          ))}
        </VStack>
      </Box>

      {/* Main Content */}
      <Box h="100%" w="100%" pt={4} pb={4} ml={20} transition="margin-left 0.2s">
        <Routes>
          <Route path="/" element={<DesignerHome />} />
          <Route path="/sourcing" element={<DesignSourcing />} />
          <Route path="/sourcing/products" element={<ProductsListPage />} />
          <Route path="/sourcing/products/:productId" element={<ProductDetailsPage />} />
          <Route
            path="/sourcing/vendors/:vendorId/products/:productId"
            element={<ProductDetailsPage />}
          />
          <Route path="/sourcing/vendors" element={<VendorsListPage />} />
          <Route path="/sourcing/vendors/:vendorId" element={<VendorDetailsPage />} />
          <Route path="/pm" element={<DesignClients />} />
          <Route path="/pm/clients" element={<ClientsListPage />} />
          <Route path="/pm/clients/:clientId" element={<ClientDetailsPage />} />
          <Route path="/pm/projects" element={<ProjectsListPage />} />
          <Route path="/pm/projects/:projectId" element={<ProjectDetailsPage />} />
          <Route
            path="/pm/clients/:clientId/projects/:projectId"
            element={<ProjectDetailsPage />}
          />
          <Route
            path="/pm/projects/:projectId/selections/:selectionId"
            element={<SelectionDetailsPage />}
          />
        </Routes>
      </Box>
    </Flex>
  );
};

export default DesignerView;
