// ./components/designers/selections/selectionFields.js

export const selectionFields = [
  [
    { fieldName: 'clientRoom', fieldHeading: 'Room', fieldType: 'string', flex: 1 },
    { fieldName: 'mdmItemNumber', fieldHeading: 'MdM Item #', fieldType: 'string', flex: 1 },
    { fieldName: 'clientName', fieldHeading: 'Client', fieldType: 'fixed', flex: 1, alwaysHidden: true },
    { fieldName: 'clientId', fieldHeading: 'Client ID', fieldType: 'fixed', flex: 1, alwaysHidden: true },
    { fieldName: 'projectName', fieldHeading: 'Project', fieldType: 'fixed', flex: 1, alwaysHidden: true },
    { fieldName: 'projectId', fieldHeading: 'Project ID', fieldType: 'fixed', flex: 1, alwaysHidden: true },
    {
      fieldName: 'supplier',
      fieldHeading: 'Supplier',
      fieldType: 'choice',
      flex: 1,
      args: {
        collection: 'vendors',
        lookupKey: 'name',
        fieldsMapping: {
          supplier: { key: 'name' },
          supplierVendorId: { key: 'id' },
        },
      },
    },
    {
      fieldName: 'manufacturer',
      fieldHeading: 'Manufacturer',
      fieldType: 'choice',
      flex: 1,
      args: {
        collection: 'vendors',
        lookupKey: 'name',
        fieldsMapping: {
          manufacturer: { key: 'name' },
          manufacturerVendorId: { key: 'id' },
        },
      },
      hidden: true,
    },
  ], [
    {
      fieldName: 'product',
      fieldHeading: 'Product',
      fieldType: 'choice',
      flex: 2,
      args: {
        collection: 'products',
        lookupKey: 'description',
        filterKey: 'supplierVendorId',
        fieldsMapping: {
          product: { key: 'description' },
          productId: { key: 'id' },
          sku: { key: 'sku' },
          description: { key: 'description' },
          clientDescription: { constant: '' },
          notes: { constant: '' },
          retailUnitPrice: { key: 'retailPrice' },
          tradeUnitPrice: { key: 'tradePrice' },
          clientUnitPrice: { key: 'clientPrice' },
          quantity: { constant: 1 },
          shippingCost: { constant: 0 },
          receivingCost: { constant: 0 },
          serviceFees: { constant: 0 },
        },
      },
      hidden: true,
    },
    { fieldName: 'sku', fieldHeading: 'SKU', fieldType: 'string', flex: 1, hidden: true },
    { fieldName: 'description', fieldHeading: 'Description', fieldType: 'string', flex: 2 },
    { fieldName: 'clientDescription', fieldHeading: 'Client Description', fieldType: 'string', flex: 2 },
    { fieldName: 'notes', fieldHeading: 'Notes', fieldType: 'string', flex: 1, hidden: true },
  ], [
    { fieldName: 'retailUnitPrice', fieldHeading: 'Retail Unit Price', fieldType: 'money', flex: 1, hidden: true },
    { fieldName: 'tradeUnitPrice', fieldHeading: 'Trade Unit Price', fieldType: 'money', flex: 1, hidden: true },
    { fieldName: 'clientUnitPrice', fieldHeading: 'Client Unit Price', fieldType: 'money', flex: 1, hidden: true },
    {
      fieldName: 'unitProfit',
      fieldHeading: 'Unit Profit',
      fieldType: 'calculated',
      flex: 1,
      args: {
        operation: 'difference',
        fields: ['clientUnitPrice', 'tradeUnitPrice'],
      },
      hidden: true,
    },
    { fieldName: 'quantity', fieldHeading: 'Quantity', fieldType: 'number', flex: 1 },
    {
      fieldName: 'retailTotalPrice',
      fieldHeading: 'Retail Total Price',
      fieldType: 'calculated',
      flex: 1,
      args: {
        operation: 'product',
        fields: ['retailUnitPrice', 'quantity'],
      },
      hidden: true,
    },
    {
      fieldName: 'tradeTotalPrice',
      fieldHeading: 'Trade Total Price',
      fieldType: 'calculated',
      flex: 1,
      args: {
        operation: 'product',
        fields: ['tradeUnitPrice', 'quantity'],
      },
      hidden: true,
    },
    {
      fieldName: 'clientTotalPrice',
      fieldHeading: 'Client Total Price',
      fieldType: 'calculated',
      flex: 1,
      args: {
        operation: 'product',
        fields: ['clientUnitPrice', 'quantity'],
      },
    },
    {
      fieldName: 'totalProfit',
      fieldHeading: 'Total Profit',
      fieldType: 'calculated',
      flex: 1,
      args: {
        operation: 'difference',
        fields: ['clientTotalPrice', 'tradeTotalPrice'],
      },
    },
  ]
];

