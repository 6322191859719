// ./components/designers/vendors/vendorFields.js

export const vendorFields = [
  [
    { fieldName: 'name', fieldHeading: 'Name', fieldType: 'string', flex: 2 },
    { fieldName: 'description', fieldHeading: 'Description', fieldType: 'string', flex: 4 },
  ], [
    { fieldName: 'isManufacturer', fieldHeading: 'Manufacturer?', fieldType: 'flag', flex: 1 },
    { fieldName: 'isSupplier', fieldHeading: 'Supplier?', fieldType: 'flag', flex: 1 },
  ]
];
