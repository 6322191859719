// ./components/designers/projects/ProjectsListPage.jsx

// React
import React from 'react';

// MdM
import EditableDbList from '../../common/lists/EditableDbList';
import Page from '../../common/pages/Page';
import { projectFields } from './projectFields';

const ProjectsListPage = () => (
  <Page
    parentTitle='Project Management'
    parentPath='/pm'
  >
    <EditableDbList
      title='Projects'
      parentPath='pm'
      listId='projects-list'
      collectionName='projects'
      fieldsConfig={projectFields}
      sortColumns={["clientName", "name"]}
    />
  </Page>
);

export default ProjectsListPage;