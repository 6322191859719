// ./components/common/lists/ColumnSettingsModal.jsx

// React
import React from 'react';

// Chakra
import {
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody, Text, VStack, HStack,
  Select, IconButton
} from '@chakra-ui/react';

// MdM
import { EyeIcon, EyeSlashIcon } from '../../../common/icons';

const ColumnSettingsModal = ({
  isOpen,
  onClose,
  fieldsConfig,
  onColumnSizeChange,
  onToggleColumn,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent bg="mdm.dark">
      <ModalHeader>Column Settings</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <VStack spacing={4} align="stretch">
          {fieldsConfig.flat().map((field) => (
            field.alwaysHidden ? null : (
              <HStack key={field.fieldName} justify="space-between">
                <Text flex={1}>{field.fieldHeading}</Text>
                <Select
                  value={field.flex || 1}
                  onChange={(e) => onColumnSizeChange(field.fieldName, e.target.value)}
                  width="80px"
                  mr={4}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
                    <option key={num} value={num}>
                      {num}x
                    </option>
                  ))}
                </Select>
                <IconButton
                  size="sm"
                  icon={field.hidden ? <EyeSlashIcon /> : <EyeIcon />}
                  onClick={() => onToggleColumn(field.fieldName)}
                  variant="ghost"
                  color={field.hidden ? "gray.500" : "blue.200"}
                  _hover={{
                    color: field.hidden ? "gray.300" : "blue.100",
                  }}
                />
              </HStack>
            )
          ))}
        </VStack>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default ColumnSettingsModal; 