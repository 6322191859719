// ./components/designers/products/productFields.js

export const productFields = [
  [
    {
      fieldName: 'supplier',
      fieldHeading: 'Supplier',
      fieldType: 'choice',
      flex: 2,
      args: {
        collection: 'vendors',
        lookupKey: 'name',
        fieldsMapping: {
          supplier: { key: 'name' },
          supplierVendorId: { key: 'id' },
        },
      },
    },
    {
      fieldName: 'manufacturer',
      fieldHeading: 'Manufacturer',
      fieldType: 'choice',
      flex: 2,
      args: {
        collection: 'vendors',
        lookupKey: 'name',
        fieldsMapping: {
            manufacturer: { key: 'name' },
            manufacturerVendorId: { key: 'id' },
        },
      },
      hidden: true,
    },
    { fieldName: 'description', fieldHeading: 'Description', fieldType: 'string', flex: 3 },
    { fieldName: 'sku', fieldHeading: 'SKU', fieldType: 'string', flex: 1 },
  ], [
    { fieldName: 'retailPrice', fieldHeading: 'Retail Price', fieldType: 'money', flex: 1 },
    { fieldName: 'tradePrice', fieldHeading: 'Trade Price', fieldType: 'money', flex: 1 },
    { fieldName: 'clientPrice', fieldHeading: 'Client Price', fieldType: 'money', flex: 1 },
    {
      fieldName: 'profit',
      fieldHeading: 'Profit',
      fieldType: 'calculated',
      flex: 1,
      args: {
        operation: 'difference',
        fields: ['clientPrice', 'tradePrice'],
      },
      hidden: true,
    },
  ]
];
