// ./components/common/DataLoading.jsx

// React
import React from 'react';

// Chakra
import { Box, Spinner } from '@chakra-ui/react';

export const CenterBox = ({ children }) => (
  <Box w="100%" h="100%" display="flex" alignItems="center" justifyContent="center">
    {children}
  </Box>
);

export const OverlaySpinner = () => (
  <Box
    position="fixed"
    top={0}
    left={0}
    w="100vw"
    h="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
    backgroundColor="rgba(0, 0, 0, 0.4)"
    zIndex={1000}
  >
    <Spinner size="xl" />
  </Box>
);