// src/utils/icons.js

import { BiSidebar } from "react-icons/bi";
import { 
  FiChevronLeft, FiHome,
} from "react-icons/fi";
import { 
  MdGroup, MdOutlineInventory2, MdBusiness,
} from "react-icons/md";
import { GoProject } from "react-icons/go";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const ClientsIcon = MdGroup;
export const CollapseMenuIcon = FiChevronLeft;
export const DesignerHomeIcon = FiHome;
export const DesignSourcingIcon = MdOutlineInventory2;
export const ProductsIcon = MdOutlineInventory2;
export const ProjectsIcon = GoProject;
export const SidebarIcon = BiSidebar;
export const VendorsIcon = MdBusiness;
export const EyeIcon = FaEye;
export const EyeSlashIcon = FaEyeSlash;