// ./components/common/lists/AddItemModal.jsx

// React
import React from 'react';

// Chakra
import {
  Button, Input, Modal, ModalOverlay, ModalContent,
  ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
  Text, VStack, HStack, Box, Select
} from '@chakra-ui/react';

// MdM
import DynamicSelect from '../fields/DynamicSelect';

const AddItemModal = ({
  isOpen,
  onClose,
  fieldsConfig,
  fields,
  onFieldChange,
  onSave,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent bg="mdm.dark">
      <ModalHeader>Add New Item</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={4} onKeyDown={(e) => e.key === 'Enter' && onSave()}>
          {fieldsConfig
            .flat()
            .map(({
              fieldName, fieldHeading, fieldType, args, fixedValue, flex, alwaysHidden,
            }) => (alwaysHidden ? null : (
              <HStack key={fieldName} w="100%">
                <Text w={200}>{fieldHeading}:</Text>
                {fieldType === 'choice' ? (
                  <Box w="100%">
                    <DynamicSelect
                      flex={flex}
                      key={fieldName}
                      fieldHeading={fieldHeading}
                      value={fields[fieldName] || ''}
                      filterValue={args?.filterKey ? fields[args.filterKey] : ''}
                      args={args}
                      onChange={(selectedValues) => onFieldChange({ ...fields, ...selectedValues })}
                    />
                  </Box>
                ) : fieldType === 'calculated' ? (
                  <Input
                    key={fieldName}
                    placeholder={fieldHeading}
                    value={fields[fieldName] ? `$${fields[fieldName].toFixed(2)}` : ''}
                    isDisabled
                  />
                ) : fieldType === 'flag' ? (
                  <Select
                    key={fieldName}
                    placeholder={`Select ${fieldHeading}`}
                    value={
                      fields[fieldName] === true
                        ? 'true'
                        : fields[fieldName] === false
                          ? 'false'
                          : ''
                    }
                    onChange={(e) => {
                      onFieldChange({
                        [fieldName]: e.target.value === 'true'
                          ? true
                          : e.target.value === 'false'
                            ? false
                            : ''
                      });
                    }}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                ) : fieldType === 'fixed' ? (
                  <Input
                    key={fieldName}
                    placeholder={fieldHeading}
                    value={fixedValue || ''}
                    isDisabled
                  />
                ) : (
                  <Input
                    key={fieldName}
                    placeholder={fieldHeading}
                    value={fields[fieldName] || ''}
                    onChange={(e) =>
                      onFieldChange({ [fieldName]: e.target.value })
                    }
                  />
                )}
              </HStack>
            ))
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" mr={3} onClick={onClose}>
          Cancel
        </Button>
        <Button colorScheme="teal" onClick={onSave}>
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default AddItemModal; 