// ./components/common/EditableList.jsx

// React
import React, { useCallback, useRef, useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Chakra
import {
  Box, Heading, HStack, IconButton, Input, Text, VStack,
} from '@chakra-ui/react';
import { AddIcon, SettingsIcon } from '@chakra-ui/icons';

// MdM Redux
import { updateFieldsConfig, initializeFieldsConfig } from '../../../redux/slices/fieldsConfigSlice';

// MdM
import EditableListItem from './EditableListItem';
import { updateCalculatedFields } from '../../../utils/calculations';
import AddItemModal from './AddItemModal';
import ColumnSettingsModal from './ColumnSettingsModal';

const EditableList = ({
  title,
  listId,
  fieldsConfig: initialFieldsConfig,
  sortColumns,
  items,
  addItem,
  saveItem,
  deleteItem,
  detailsPath,
}) => {
  // ------------------------------ Hooks ------------------------------ //
  
  // State
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addModalFields, setAddModalFields] = useState({});
  const [search, setSearch] = useState('');
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  // Refs
  const nameInputRef = useRef(null);

  // Redux
  const dispatch = useDispatch();
  const fieldsConfig = useSelector((state) => state.fieldsConfig.configs[listId] || initialFieldsConfig);

  // Initialize fieldsConfig in Redux when component mounts
  useEffect(() => {
    dispatch(initializeFieldsConfig({ listId, config: initialFieldsConfig }));
  }, [dispatch, listId, initialFieldsConfig]);

  // ------------------------------ Callbacks ------------------------------ //

  const handleOpenModal = useCallback(() => {
    setIsAddModalOpen(true);
    setTimeout(() => nameInputRef.current?.focus(), 0);
  }, []);

  const handleCloseAddModal = useCallback(() => {
    setIsAddModalOpen(false);
    setAddModalFields({});
  }, []);

  const handleAddItem = useCallback(() => {
    // Ensure fixed fields are included in newFields
    const updatedFields = fieldsConfig.flat().reduce((acc, column) => {
      if (column.fieldType === 'fixed') {
        acc[column.fieldName] = column.fixedValue;
      } else if (column.fieldType === 'money' && addModalFields[column.fieldName]) {
        acc[column.fieldName] = parseFloat(addModalFields[column.fieldName]);
      }
      return acc;
    }, { ...addModalFields });

    addItem(updatedFields);
    handleCloseAddModal();
  }, [fieldsConfig, addModalFields, addItem, handleCloseAddModal]);

  const handleToggleColumn = useCallback((fieldName) => {
    const newConfig = fieldsConfig.map((group) =>
      group.map((field) =>
        field.fieldName === fieldName
          ? { ...field, hidden: !field.hidden }
          : field
      )
    );
    dispatch(updateFieldsConfig({ listId, config: newConfig }));
  }, [dispatch, listId, fieldsConfig]);

  const handleColumnSizeChange = useCallback((fieldName, size) => {
    const numSize = parseInt(size, 10);
    if (isNaN(numSize) || numSize < 1 || numSize > 9) return;
    
    const newConfig = fieldsConfig.map((group) =>
      group.map((field) =>
        field.fieldName === fieldName
          ? { ...field, flex: numSize }
          : field
      )
    );
    dispatch(updateFieldsConfig({ listId, config: newConfig }));
  }, [dispatch, listId, fieldsConfig]);

  // ------------------------------ Render ------------------------------ //

  return (
    <VStack w="100%" h="100%" align="start">
      {/* Header Row */}
      <HStack w="100%" mb={2} justifyContent="space-between" alignItems="center">
        <Heading>{title}</Heading>
        <Input
          ml={8}
          mr={8}
          placeholder="Search items..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          flex={1}
        />
        <IconButton
          aria-label="Column settings"
          icon={<SettingsIcon />}
          colorScheme="blue"
          onClick={() => setIsSettingsModalOpen(true)}
          mr={2}
        />
        <IconButton
          aria-label="Add new item"
          icon={<AddIcon />}
          colorScheme="teal"
          onClick={handleOpenModal}
        />
      </HStack>

      <VStack w="100%" flex={1} spacing={0} align="start">
        {/* Column Headings */}
        <HStack
          w="100%"
          p={2}
          pl={4}
          pr={4}
          bg="mdm.dark_medium"
          borderWidth="1px"
          borderRadius="md"
          alignItems="center"
          spacing={4}
        >
          {fieldsConfig
            .flat()
            .map(({ fieldName, fieldHeading, flex, hidden, alwaysHidden }) => (
              hidden || alwaysHidden ? null : (
                <Text
                  key={fieldName}
                  fontSize="md"
                  flex={flex || 1}
                  fontWeight="bold"
                  noOfLines={1}
                >
                  {fieldHeading}
                </Text>
              )
            ))}
          <Box h="100%" w={24} />
        </HStack>


        {/* Scrollable list area */}
        <Box
          w="100%"
          flex="1"
          overflowY="auto"
          borderWidth="1px"
          borderRadius="md"
          p={2}
          maxH="calc(100vh - 300px)"
        >
          {items
            .filter((item) =>
              fieldsConfig.flat().some(({ fieldName }) =>
                item[fieldName]?.toString().toLowerCase().includes(search.toLowerCase())
              )
            )
            .slice()
            .sort((a, b) => {
              let comparison = 0;
              sortColumns.forEach((col) => {
                if (comparison === 0) {
                  const aValue = a[col] || '';
                  const bValue = b[col] || '';
                  comparison = aValue.localeCompare(bValue);
                }
              });
              if (comparison !== 0) return comparison;
              return 0;
            })
            .map((item) => (
              <EditableListItem
                key={item.id}
                fieldsConfig={fieldsConfig}
                fields={item}
                onSave={(updatedData) => saveItem(item.id, updatedData)}
                onDelete={() => deleteItem(item.id)}
                detailsPath={detailsPath}
              />
          ))}
        </Box>
      </VStack>

      <AddItemModal
        isOpen={isAddModalOpen}
        onClose={handleCloseAddModal}
        fieldsConfig={fieldsConfig}
        fields={addModalFields}
        onFieldChange={(updates) => (
          setAddModalFields(updateCalculatedFields({
            ...addModalFields, ...updates,
          }, fieldsConfig))
        )}
        onSave={handleAddItem}
      />

      <ColumnSettingsModal
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        fieldsConfig={fieldsConfig}
        onColumnSizeChange={handleColumnSizeChange}
        onToggleColumn={handleToggleColumn}
      />
    </VStack>
  );
};

export default EditableList;