// ./components/common/hooks/useDocDetailsData.js

// React
import { useCallback, useEffect, useState } from 'react';

// MdM
import { getDocumentById, saveDocument } from '../../../data/documents';
import { updateCalculatedFields } from '../../../utils/calculations';

/**
 * Custom hook for handling document details pages data loading and state
 * @param {string} collectionName - The Firestore collection name
 * @param {string} documentId - The document ID to fetch
 * @returns {Object} - Contains data, loading state, and fetch function
 */
const useDocDetailsData = (collectionName, documentId, fieldsConfig) => {
  // ------------------------------ Hooks ------------------------------ //

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  // ------------------------------ Callbacks ------------------------------ //

  const fetchData = useCallback(async () => {
    try {
      const document = await getDocumentById(collectionName, documentId);
      setData(document);
    } catch (error) {
      console.error(`Error fetching ${collectionName} data:`, error);
    } finally {
      setLoading(false);
    }
  }, [collectionName, documentId]);

  const onSave = useCallback((updates) => {
    setLoading(true);
    const updatedFields = updateCalculatedFields({ ...data, ...updates }, fieldsConfig);
    return saveDocument(collectionName, documentId, updatedFields)
      .then(() => fetchData(documentId))
      .finally(() => setLoading(false));
  }, [data, fieldsConfig, collectionName, documentId, fetchData]);

  // ------------------------------ Effects ------------------------------ //

  useEffect(() => {
    if (documentId) {
      fetchData(documentId);
    }
  }, [documentId, fetchData]);

  // ------------------------------ Return ------------------------------ //

  return { data, loading, onSave };
};

export default useDocDetailsData; 