// ./components/designers/selections/SelectionDetailsPage.jsx

// React
import React from 'react';

// Router
import { useParams } from 'react-router-dom';

// MdM
import DetailsPage from '../../common/pages/DetailsPage';
import useDocDetailsData from '../../common/hooks/useDocDetailsData';
import { selectionFields } from './selectionFields';

const SelectionDetailsPage = () => {
  // ------------------------------ Hooks ------------------------------ //

  const { selectionId, projectId } = useParams();
  const {
    data: selectionData, loading, onSave,
  } = useDocDetailsData('selections', selectionId, selectionFields);

  // ------------------------------ Render ------------------------------ //

  return (
    <DetailsPage
      docData={selectionData}
      loading={loading}
      heading={`Selection: ${selectionData?.description}`}
      parentTitle={'Project Details'}
      parentPath={`/pm/projects/${projectId}`}
      fieldsConfig={selectionFields}
      onSave={onSave}
    />
  );
}

export default SelectionDetailsPage; 