// ./components/designers/clients/ClientDetailsPage.jsx

// React
import React, { useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Firestore
import { where } from 'firebase/firestore';

// MdM
import DetailsPage from '../../common/pages/DetailsPage';
import EditableDbList from '../../common/lists/EditableDbList';
import useDocDetailsData from '../../common/hooks/useDocDetailsData';
import { projectFields } from '../projects/projectFields';
import { clientFields } from './clientFields';

const ClientDetailsPage = () => {
  // ------------------------------ Hooks ------------------------------ //

  const { clientId } = useParams();
  const {
    data: clientData, loading, onSave,
  } = useDocDetailsData('clients', clientId, clientFields);

  // ------------------------------ Memos ------------------------------ //

  // Modify project fields config to override client fields
  const modifiedProjectFields = useMemo(() => {
    if (!clientData) return null;
    return projectFields.map(columnFields => {
      // 1. We're mapping over projectFields, which is an array of arrays
      // Each columnFields is one of those inner arrays

      // 2. We only modify the array that contains clientName
      if (columnFields.some(field => field.fieldName === 'clientName')) {
        return [
          // 3. This spread operator maps all fields in the original array
          ...columnFields.map(fieldConfig => {
            // 3a. If it's clientName, modify it
            if (fieldConfig.fieldName === 'clientName') {
              return {
                ...fieldConfig,
                fieldType: 'fixed',
                args: undefined,
                fixedValue: clientData.displayName,
              };
            }
            // 3b. If it's not clientName, return unchanged
            return fieldConfig;
          }),
          // 4. Add the new clientId field to this same array
          {
            fieldName: 'clientId',
            fieldHeading: 'Client ID',
            fieldType: 'fixed',
            fixedValue: clientId,
            alwaysHidden: true,
          }
        ];
      }
      // 5. For arrays that don't contain clientName, return them unchanged
      return columnFields;
    });
  }, [clientData, clientId]);

  // ------------------------------ Render ------------------------------ //

  return (
    <DetailsPage
      docData={clientData}
      loading={loading}
      heading={`Client: ${clientData?.displayName}`}
      parentTitle={"Clients"}
      parentPath={"/pm/clients"}
      fieldsConfig={clientFields}
      onSave={onSave}
    >
      <EditableDbList
        title="Projects"
        parentPath={`pm/clients/${clientId}`}
        listId='client-projects-list'
        collectionName="projects"
        fieldsConfig={modifiedProjectFields}
        sortColumns={["name"]}
        dbQuery={[where('clientId', '==', clientId)]}
      />
    </DetailsPage>
  );
}

export default ClientDetailsPage;
