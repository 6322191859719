// ./components/designers/vendors/VendorsListPage.jsx

// React
import React from 'react';

// MdM
import EditableDbList from '../../common/lists/EditableDbList';
import Page from '../../common/pages/Page';
import { vendorFields } from './vendorFields';

const VendorsListPage = () => (
  <Page
    parentTitle="Design Sourcing"
    parentPath="/sourcing"
  >
    <EditableDbList
      title='Vendors'
      parentPath="sourcing"
      listId='vendors-list'
      collectionName="vendors"
      fieldsConfig={vendorFields}
      sortColumns={["name"]}
    />
  </Page>
);

export default VendorsListPage;