// ./components/designers/clients/ClientsListPage.jsx

// React
import React from 'react';

// MdM
import EditableDbList from '../../common/lists/EditableDbList';
import Page from '../../common/pages/Page';
import { clientFields } from './clientFields';

const ClientsListPage = () => (
  <Page
    parentTitle='Project Management'
    parentPath='/pm'
  >
    <EditableDbList
      title='Clients'
      parentPath='pm'
      listId='clients-list'
      collectionName='clients'
      fieldsConfig={clientFields}
      sortColumns={["displayName"]}
    />
  </Page>
);

export default ClientsListPage;